* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 20px;
}

body {
  background-color: #121212;
  background-image: url(./assets/images/JinxBG.png);
  background-position-x: right;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: #dddddd;
  font-family: "Lusitana", serif;
  padding: 20px;
}

a {
  color: #2376b5;
  text-decoration: none;
}

a:hover {
  color: #88dd00;
}

header h1 {
  margin: 20px auto;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2rem;
  font-weight: normal;
  text-transform: uppercase;
}

h2,
h3,
h4 {
  font-weight: normal;
}

h2 {
  font-size: 1.5rem;
  margin: 1rem 0 0 0;
}

h3,
h4 {
  font-size: 0.75rem;
  margin: 0.5rem 0 0 0;
}

.blog-content,
.blog-post {
  padding-top: 3rem;
  max-width: 600px;
}

img {
  width: 100%;
  max-width: 600px;
}

p {
  margin: 1rem 0;
  line-height: 1.5em;
}

footer {
  font-size: 0.75rem;
  padding: 100px 0 20px 0;
}

footer ul {
  list-style: none;
}
